// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
  }

  .dot {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #b3d4fc;
    animation: pulse 1.5s infinite ease-in-out;
  }

  .dot:last-child {
    margin-right: 0;
  }

  .dot:nth-child(1) {
    animation-delay: -0.3s;
  }

  .dot:nth-child(2) {
    animation-delay: -0.1s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.1s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      background-color: #b3d4fc;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }

    50% {
      transform: scale(1.2);
      background-color: #6793fb;
      box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
    }

    100% {
      transform: scale(0.8);
      background-color: #b3d4fc;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,WAAW;EACb;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,0CAA0C;EAC5C;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE;MACE,qBAAqB;MACrB,yBAAyB;MACzB,4CAA4C;IAC9C;;IAEA;MACE,qBAAqB;MACrB,yBAAyB;MACzB,6CAA6C;IAC/C;;IAEA;MACE,qBAAqB;MACrB,yBAAyB;MACzB,4CAA4C;IAC9C;EACF","sourcesContent":[".dots-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    width: 100%;\n  }\n\n  .dot {\n    height: 20px;\n    width: 20px;\n    margin-right: 10px;\n    border-radius: 10px;\n    background-color: #b3d4fc;\n    animation: pulse 1.5s infinite ease-in-out;\n  }\n\n  .dot:last-child {\n    margin-right: 0;\n  }\n\n  .dot:nth-child(1) {\n    animation-delay: -0.3s;\n  }\n\n  .dot:nth-child(2) {\n    animation-delay: -0.1s;\n  }\n\n  .dot:nth-child(3) {\n    animation-delay: 0.1s;\n  }\n\n  @keyframes pulse {\n    0% {\n      transform: scale(0.8);\n      background-color: #b3d4fc;\n      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);\n    }\n\n    50% {\n      transform: scale(1.2);\n      background-color: #6793fb;\n      box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);\n    }\n\n    100% {\n      transform: scale(0.8);\n      background-color: #b3d4fc;\n      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
