// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/images/login_img.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-modal {
    width: 90%;
    max-width: 70rem;
}

.login-modal-content {
    display: flex;
    justify-content: space-between;
}

.login-modal-left {
    width: 50%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
}

.login-modal-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    position: relative;
}

.login-box {
    width: 100%;
    margin-top: 0 !important;
}

@media (max-width: 768px) {
    .login-modal-content {
        flex-direction: column;
    }

    .login-modal-left {
        display: none;
    }


    .login-modal-right {
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        position: relative;
    }

    .login-box {
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/login/NewLogin.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,yDAA4D;IAC5D,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,wBAAwB;AAC5B;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,aAAa;IACjB;;;IAGA;QACI,WAAW;QACX,aAAa;QACb,sBAAsB;QACtB,uBAAuB;QACvB,mBAAmB;QACnB,eAAe;QACf,kBAAkB;IACtB;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".login-modal {\n    width: 90%;\n    max-width: 70rem;\n}\n\n.login-modal-content {\n    display: flex;\n    justify-content: space-between;\n}\n\n.login-modal-left {\n    width: 50%;\n    background-image: url('/public/assets/images/login_img.jpg');\n    background-size: cover;\n    background-position: center;\n}\n\n.login-modal-right {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 4rem;\n    position: relative;\n}\n\n.login-box {\n    width: 100%;\n    margin-top: 0 !important;\n}\n\n@media (max-width: 768px) {\n    .login-modal-content {\n        flex-direction: column;\n    }\n\n    .login-modal-left {\n        display: none;\n    }\n\n\n    .login-modal-right {\n        width: auto;\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        padding: 1.5rem;\n        position: relative;\n    }\n\n    .login-box {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
