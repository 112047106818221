// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0.7rem 0; */
}

.topLeft {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.topRight {
    display: flex;
    align-items: center;
    gap: 0.9rem;
}

.topContainer .text {
    font-size: 0.7rem;
    font-weight: 600;
}

.navContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

/* .container{
    background: #FFF;
    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.16);
} */

@media (max-width: 768px) {
  .desktopMenu {
    display: none !important;
  }
  .mobileMenu {
    display: flex !important;
  }
}

@media (min-width: 769px) {
  .desktopMenu {
    display: flex !important;
  }
  .mobileMenu {
    display: none !important;
  }
}

@media (max-width: 400px) {
  .topContainer {
    zoom: 80%
  }
}`, "",{"version":3,"sources":["webpack://./src/components/topNavbar/topNavbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;;;GAGG;;AAEH;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE;EACF;AACF","sourcesContent":[".topContainer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    /* padding: 0.7rem 0; */\n}\n\n.topLeft {\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n}\n\n.topRight {\n    display: flex;\n    align-items: center;\n    gap: 0.9rem;\n}\n\n.topContainer .text {\n    font-size: 0.7rem;\n    font-weight: 600;\n}\n\n.navContainer {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n/* .container{\n    background: #FFF;\n    box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.16);\n} */\n\n@media (max-width: 768px) {\n  .desktopMenu {\n    display: none !important;\n  }\n  .mobileMenu {\n    display: flex !important;\n  }\n}\n\n@media (min-width: 769px) {\n  .desktopMenu {\n    display: flex !important;\n  }\n  .mobileMenu {\n    display: none !important;\n  }\n}\n\n@media (max-width: 400px) {\n  .topContainer {\n    zoom: 80%\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
