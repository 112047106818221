// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewAll button{
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    /* background-color: #F09300; */
    background: linear-gradient(90deg, #F09300 0%, #FFB800 100%);
    padding: 10px 30px 10px 30px;
    border-radius: 50px;
    border: None;

}

/* width: Hug (134px)px;
height: Hug (42px)px;
padding: 12px 30px 12px 30px;
gap: 0px;
border-radius: 50px 0px 0px 0px;
opacity: 0px; */
`, "",{"version":3,"sources":["webpack://./src/components/viewAllButton/viewAll.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,+BAA+B;IAC/B,4DAA4D;IAC5D,4BAA4B;IAC5B,mBAAmB;IACnB,YAAY;;AAEhB;;AAEA;;;;;eAKe","sourcesContent":[".viewAll button{\n    font-size: 1rem;\n    font-weight: 600;\n    width: 100%;\n    /* background-color: #F09300; */\n    background: linear-gradient(90deg, #F09300 0%, #FFB800 100%);\n    padding: 10px 30px 10px 30px;\n    border-radius: 50px;\n    border: None;\n\n}\n\n/* width: Hug (134px)px;\nheight: Hug (42px)px;\npadding: 12px 30px 12px 30px;\ngap: 0px;\nborder-radius: 50px 0px 0px 0px;\nopacity: 0px; */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
