// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.book-cards{
    display: flex;
}
.book-cards > .card{
    width: 50%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    padding: 24px;
}
.book-cards .content > * {
    padding-bottom: 10px;
}
.book-cards .image{
    width:85%
}
.book-cards .title{
    font-size: 1.2rem;
    font-weight: 600;
}
.desc{
    font-size: 1rem;
    font-weight: 400;
}
.cost{
    font-size: 1.2rem;
    font-weight: 600;
    color: #F09300;
}
.buyButton{
    width: 100%;
    background: linear-gradient(90deg, #F09300 0%, #FFB800 100%);
}

@media (max-width: 768px) {
    .book-cards {
        flex-direction: column;
        align-items: center;
    }

    .book-cards > .card {
        width: 90%;
        margin-bottom: 20px;
    }

    .book-cards .image {
        width: 100%;
    }

    .book-cards .title {
        font-size: 1rem;
    }

    .desc {
        font-size: 0.9rem;
    }

    .cost {
        font-size: 1rem;
    }

    .buyButton {
        width: 100%;
        background: linear-gradient(90deg, #F09300 0%, #FFB800 100%);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/popularBooks/popularBooks.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,UAAU;IACV,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,oBAAoB;AACxB;AACA;IACI;AACJ;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,WAAW;IACX,4DAA4D;AAChE;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;IACvB;;IAEA;QACI,UAAU;QACV,mBAAmB;IACvB;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,WAAW;QACX,4DAA4D;IAChE;AACJ","sourcesContent":[".book-cards{\n    display: flex;\n}\n.book-cards > .card{\n    width: 50%;\n    display: flex;\n    text-align: center;\n    flex-direction: column;\n    align-items: center;\n    padding: 24px;\n}\n.book-cards .content > * {\n    padding-bottom: 10px;\n}\n.book-cards .image{\n    width:85%\n}\n.book-cards .title{\n    font-size: 1.2rem;\n    font-weight: 600;\n}\n.desc{\n    font-size: 1rem;\n    font-weight: 400;\n}\n.cost{\n    font-size: 1.2rem;\n    font-weight: 600;\n    color: #F09300;\n}\n.buyButton{\n    width: 100%;\n    background: linear-gradient(90deg, #F09300 0%, #FFB800 100%);\n}\n\n@media (max-width: 768px) {\n    .book-cards {\n        flex-direction: column;\n        align-items: center;\n    }\n\n    .book-cards > .card {\n        width: 90%;\n        margin-bottom: 20px;\n    }\n\n    .book-cards .image {\n        width: 100%;\n    }\n\n    .book-cards .title {\n        font-size: 1rem;\n    }\n\n    .desc {\n        font-size: 0.9rem;\n    }\n\n    .cost {\n        font-size: 1rem;\n    }\n\n    .buyButton {\n        width: 100%;\n        background: linear-gradient(90deg, #F09300 0%, #FFB800 100%);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
