import React from 'react'
import VideoPlayerCard from './videoPlayerCard'
import AudioPlayerCard from './AudioPlayerCard'

export default function AudioVideo() {
  return (
    <div>
        <VideoPlayerCard />
        <AudioPlayerCard />
    </div>
  )
}
